import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"]
})
export class CategoriesComponent implements OnInit {
  newCategory = {
    id: "",
    name: "",
    time: ""
  };
  newSubCategory = {
    id: "",
    name: "",
    time: "",
    parentId: "",
    parentName: ""
  };

  selectedCategory = {
    id: "",
    name: "",
    time: ""
  };

  categories = [];
  subCategories = [];

  addCategoryFeild = false;
  addSubCategoryFeild = false;

  constructor(
    private firestoreService: FirestoreServiceService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.loadCategories();
  }

  addCategory() {
    if (this.isCategoryValid()) {
      // console.log(this.newCategory);

      this.firestoreService
        .addCategories(this.newCategory)
        .then(data => {
          this.newCategory.name = "";
          this.loadCategories();
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }
  isCategoryValid() {
    if (this.newCategory.name.trim() === "") {
      alert("Enter category");
      return false;
    }
    return true;
  }

  isSubCategoryValid() {
    if (this.newSubCategory.name.trim() === "") {
      alert("Enter sub-category name");
      return false;
    }
    if (this.newSubCategory.parentId === "") {
      // console.log("parent id not provided");
      return false;
    }
    if (this.newSubCategory.parentName === "") {
      // console.log("parent name not provided");
      return false;
    }
    return true;
  }
  addSubCategory() {
    if (this.isSubCategoryValid()) {
      // console.log(this.newSubCategory);

      this.firestoreService
        .addSubCategories(this.newSubCategory)
        .then(data => {
          // console.log(data);
          this.newSubCategory.name = "";
          this.loadSubCategories(this.selectedCategory);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }

  loadCategories() {
    this.firestoreService
      .getAllCategories()
      .toPromise()
      .then(querySnapshot => {
        this.categories = [];
        querySnapshot.forEach(doc => {
          this.categories.push(doc.data());
        });
        if (this.categories[0]) {
          this.selectedCategory = this.categories[0];
          this.loadSubCategories(this.selectedCategory);

          this.newSubCategory.parentId = this.selectedCategory.id;
          this.newSubCategory.parentName = this.selectedCategory.name;
        }
      });
  }
  loadSubCategories(category) {
    this.selectedCategory = category;
    this.newSubCategory.parentId = category.id;
    this.newSubCategory.parentName = category.name;

    this.subCategories = [];

    this.firestoreService
      .getAllSubCategories(category.id)
      .toPromise()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.subCategories.push(doc.data());
        });
      });
  }
  deleteCategory(catId) {
    this.ngxService.start();

    this.firestoreService
      .deleteCategory(catId)
      .then(data => {
        this.showSuccessToast(
          "Deleted",
          "Successfully deleted the category with id: " + catId
        );
        this.loadCategories();
        this.ngxService.stop();
      })
      .catch(e => {
        // console.log(e);
        this.ngxService.stop();
        this.showErrorToast("Error", e.message);
      });
  }
  deleteSubCategory(catId) {
    this.ngxService.start();

    this.firestoreService
      .deleteSubCategory(catId)
      .then(data => {
        this.showSuccessToast(
          "Deleted",
          "Successfully deleted the sub-category with id: " + catId
        );
        this.loadCategories();
        this.ngxService.stop();
      })
      .catch(e => {
        // console.log(e);
        this.showErrorToast("Error", e.message);
        this.ngxService.stop();
      });
  }

  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }
}
