import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { FileUpload } from "src/app/classes/file-upload";
import { FirebaseApp } from "@angular/fire";
import { Router, ActivatedRoute } from "@angular/router";

import * as firebase from "firebase/app";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-products",
  templateUrl: "./edit-product.component.html",
  styleUrls: ["./edit-product.component.scss"],
})
export class EditProductComponent implements OnInit {
  // newProduct = {
  //   id: "",
  //   title: "",
  //   images: {},
  //   rateType: "simple",
  //   price: "",
  //   attributeId: "",
  //   variations: {},
  //   categoryId: "",
  //   subCategoryId: "",
  //   subCategoryName: "",
  //   properties: {}
  // };
  newProduct: any = null;
  product: any = null;

  images = [];
  inStock = "yes";

  categories = [];
  subCategories = [];
  attributes = [];

  selectedCategory = {
    id: "",
    name: "",
    time: "",
  };
  variations: any = [
    {
      name: "",
      price: "",
    },
  ];
  properties = [
    {
      name: "",
      value: "",
    },
  ];

  imagesArray = {};

  // file uploading
  imageUrl = "";
  uploading = false;
  uploadPercent = 0;
  uploaded = false;

  private pdfPath = "/productImages";
  selectedFile = null;
  currentUpload: any = null;

  constructor(
    private db: AngularFirestore,
    private firestoreService: FirestoreServiceService,
    private firebaseApp: FirebaseApp,
    private ngxService: NgxUiLoaderService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router
  ) {
    const routeParams = this.activeRoute.snapshot.params;
    this.loadProduct(routeParams.id);
    // console.log(routeParams.id);

    this.loadCategories();
    this.loadAttributes();
  }
  loadProduct(id) {
    this.firestoreService
      .getProductwithId(id)
      .get()
      .toPromise()
      .then((productSnapshot) => {
        // console.log(productSnapshot.data());
        this.newProduct = productSnapshot.data();
        this.images = this.newProduct.images;
        this.variations = this.newProduct.variations;
        this.properties = this.newProduct.properties;
        // this.properties = this.product.properties;
        // this.getAttribute(this.product.attributeId);
        // if (this.product.rateType === "variable") {
        //   this.product.price = this.product.variations[0].price;
        // console.log(this.newProduct);
        // console.log(this.newProduct.variations);

        if (!this.newProduct.inStock) {
          this.newProduct.inStock = "yes";
        }

        this.loadSubCategories(this.newProduct.categoryId);
        // }
      })
      .catch((error) => {
        this.moveToProducts();
      });
  }
  ngOnInit() { }
  resetFeilds() {
    this.newProduct = {
      id: "",
      title: "",
      images: {},
      rateType: "simple",
      price: "",
      attributeId: "",
      variations: {},
      categoryId: "",
      subCategoryId: "",
      subCategoryName: "",
      properties: {},
    };
    this.variations = [
      {
        name: "",
        price: "",
      },
    ];
    this.properties = [
      {
        name: "",
        value: "",
      },
    ];
    this.images = [];
  }
  validateProduct() {
    if (this.images.length < 1) {
      this.showErrorToast("Image Error", "upload atleast one image");
      return false;
    }
    if (this.newProduct.title.trim() === "") {
      this.showErrorToast("Input Error", "Enter product title");
      return false;
    }
    if (this.newProduct.rateType === "simple" && this.newProduct.price == "") {
      this.showErrorToast("Input Error", "Enter product price");
      return false;
    }
    if (this.newProduct.rateType === "variable" && this.validateVariations()) {
      // this.showErrorToast("Input Error", "Enter product price");
      return false;
    }
    if (this.newProduct.categoryId === "") {
      this.showErrorToast("Input Error", "Select product Category");
      return false;
    }
    if (this.newProduct.subCategoryId === "") {
      this.showErrorToast("Input Error", "Select product Sub-Category");
      return false;
    }

    return true;
  }
  validateVariations() {
    if (this.variations.length < 1) {
      this.showErrorToast("Input Error", "Enter variable rate");
      return true;
    }
    let err = false;
    this.variations.forEach((variation) => {
      if (variation.name.trim() == "" || variation.price == "") {
        this.showErrorToast("Price Error", "Fill all variation fields");
        err = true;
      }
    });
    if (err) {
      return true;
    }
    return false;
  }
  updateProduct() {
    if (this.validateProduct()) {
      this.ngxService.start();

      if (this.newProduct.rateType === "variable") {
        this.variations.sort(function (a, b) {
          return a.price - b.price;
        });
        this.newProduct.variations = this.variations;
        this.newProduct.price = "";
      }
      this.newProduct.properties = this.properties;
      this.newProduct.images = this.images;
      // console.log(this.newProduct);

      this.firestoreService
        .updateProductToFirestore(this.newProduct)
        .then((data) => {
          // console.log(data);
          this.showSuccessToast("Product Updated Successfully", "");
          this.ngxService.stop();
          // this.resetFeilds();
          this.moveToProducts();
        })
        .catch((error) => {
          // console.log(error);
          this.ngxService.stop();
        });
    }
  }
  loadCategories() {
    this.firestoreService
      .getAllCategories()
      .toPromise()
      .then((querySnapshot) => {
        this.categories = [];
        querySnapshot.forEach((doc) => {
          this.categories.push(doc.data());
        });
        if (this.categories[0]) {
          this.selectedCategory = this.categories[0];
          // this.loadSubCategories(this.categories[0].id);
        }
      });
  }
  loadSubCategories(categoryId) {
    // console.log(categoryId);

    // this.selectedCategory = category;

    this.subCategories = [];

    this.firestoreService
      .getAllSubCategories(categoryId)
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.subCategories.push(doc.data());
        });
      });
  }

  loadAttributes() {
    this.firestoreService
      .getAllAttributes()
      .toPromise()
      .then((querySnapshot) => {
        this.attributes = [];
        querySnapshot.forEach((doc) => {
          this.attributes.push(doc.data());
        });
        // if (this.attributes[0]) {
        //   this.selectedAttribute = this.attributes[0];
        //   this.loadSubAttributes(this.attributes[0].id);
        // }
      });
  }
  removeViriation(i) {
    this.variations.splice(i, 1);
  }
  removeProperty(i) {
    this.properties.splice(i, 1);
  }

  addVariation() {
    this.variations.push({
      name: "",
      price: "",
    });
  }

  addProperty() {
    this.properties.push({
      name: "",
      value: "",
    });
  }

  detectFiles(event) {
    // console.log(event.target.files[0]);
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const file = this.selectedFile;
      this.currentUpload = new FileUpload(file);
      this.pushUploadPDF(this.currentUpload);
      this.selectedFile = null;
    } else {
      // this.toastr.error("select an image file...")
      // console.log("select PDF");
    }
  }

  pushUploadPDF(upload) {
    const storageRef = this.firebaseApp.storage().ref();
    const uploadTask = storageRef
      .child(`${this.pdfPath}/${this.getRandomNumber() + upload.file.name}`)
      .put(upload.file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        this.uploading = true;
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        this.uploadPercent = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        // console.log(this.uploadPercent);
      },
      (error) => {
        // this.uploading = false;
        // upload failed
        // console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.uploaded = true;
          this.uploading = false;
          this.uploadPercent = 0;
          // this.newNotification.fileUrl = downloadURL;
          this.addToImagesArray(downloadURL);
          // console.log(downloadURL);
        });
      }
    );
  }

  getRandomNumber() {
    let num = "";
    for (let i = 0; i < 15; i++) {
      num = num + "" + Math.floor(Math.random() * 10);
    }
    return num;
  }

  addToImagesArray(imageUrl) {
    // this.addToImagesArray.push()
    // console.log(imageUrl);
    this.images.push({
      imageUrl: imageUrl,
    });
  }
  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }

  onChangeAttributeSelect(name) { }
  moveToProducts() {
    this.router.navigate(["/admin/allProducts"]);
  }
}
