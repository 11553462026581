import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";

@Component({
  selector: "app-attributes",
  templateUrl: "./attributes.component.html",
  styleUrls: ["./attributes.component.scss"]
})
export class AttributesComponent implements OnInit {
  isAddingAttribute = false;
  isAddingSubAttribute = false;

  attributes = [];
  subAttributes = [];
  newAttribute = {
    id: "",
    name: "",
    time: ""
  };
  newSubAttribute = {
    id: "",
    name: "",
    time: "",
    parentId: ""
  };
  selectedAttribute = {
    id: "",
    name: "",
    time: ""
  };
  constructor(private firestoreService: FirestoreServiceService) {}

  ngOnInit() {
    this.loadAttributes();
  }

  loadAttributes() {
    this.firestoreService
      .getAllAttributes()
      .toPromise()
      .then(querySnapshot => {
        this.attributes = [];
        querySnapshot.forEach(doc => {
          this.attributes.push(doc.data());
        });
        if (this.attributes[0]) {
          this.selectedAttribute = this.attributes[0];
          this.loadSubAttributes(this.attributes[0].id);
        }
      });
  }

  loadSubAttributes(attributeId) {
    this.firestoreService
      .getAllSubAttributes(attributeId)
      .toPromise()
      .then(querySnapshot => {
        this.subAttributes = [];
        querySnapshot.forEach(doc => {
          this.subAttributes.push(doc.data());
        });
      });
  }

  addAttribute() {
    if (this.isAttributeValid()) {
      // console.log(this.newAttribute);

      this.firestoreService
        .addAttributes(this.newAttribute)
        .then(data => {
          this.newAttribute.name = "";
          this.loadAttributes();
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }
  addSubAttribute() {
    this.newSubAttribute.parentId = this.selectedAttribute.id;
    if (this.isSubAttributeValid()) {
      // console.log(this.newSubAttribute);

      this.firestoreService
        .addSubAttribute(this.selectedAttribute.id, this.newSubAttribute)
        .then(data => {
          this.newSubAttribute.name = "";
          this.loadSubAttributes(this.selectedAttribute.id);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  }

  isAttributeValid() {
    if (this.newAttribute.name.trim() === "") {
      alert("Enter attribute");
      return false;
    }
    return true;
  }

  isSubAttributeValid() {
    if (this.newSubAttribute.name.trim() === "") {
      alert("Enter sub-attribute name");
      return false;
    }
    if (this.newSubAttribute.parentId === "") {
      // console.log("parent id not provided");
      return false;
    }
    return true;
  }
}
