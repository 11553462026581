import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-shortlist",
  templateUrl: "./shortlist.component.html",
  styleUrls: ["./shortlist.component.scss"]
})
export class ShortlistComponent implements OnInit {
  cartItems = [{}, {}, {}];
  constructor() {}

  ngOnInit() {}
}
