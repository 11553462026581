import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";

@Component({
  selector: "app-featured",
  templateUrl: "./featured.component.html",
  styleUrls: ["./featured.component.scss"]
})
export class FeaturedComponent implements OnInit {
  productList = [];
  constructor(private firestoreService: FirestoreServiceService) {}

  ngOnInit() {
    this.loadFeaturedPrducts();
  }
  loadFeaturedPrducts() {
    this.productList = [];
    this.firestoreService
      .getLastProductsByLimit(8)
      .toPromise()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.productList.push(doc.data());
        });
        // this.productList = this.productList.splice(0, 8);
        // console.log(this.productList);
      });
  }
}
