import { FlatTreeControl } from "@angular/cdk/tree";
import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { auth } from "firebase";
import { CartServiceService } from "src/app/services/cart-service.service";

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"]
})
export class SideMenuComponent implements OnInit {
  categories = [];
  subCategories = [];

  constructor(private firestoreService: FirestoreServiceService,) {

  }

  ngOnInit() {
    this.loadSubCategories();
  }
  loadCategories() {
    this.firestoreService
      .getAllCategories()
      .toPromise()
      .then(querySnapshot => {
        this.categories = [];
        querySnapshot.forEach(doc => {
          this.categories.push(doc.data());
        });
      });
  }
  loadSubCategories() {
    this.subCategories = [];
    this.firestoreService
      .getAllSubCategoriesForSideMenu()
      .toPromise()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.subCategories.push(doc.data());
        });
        this.loadCategories();
      });
  }
  getSubCategories(categoryId) {
    const temp = [];
    this.subCategories.forEach(element => {
      if (element.parentId === categoryId) {
        temp.push(element);
      }
    });
    return temp;
  }
  applyCollapsibleProperty() {
    // var coll = document.getElementsByClassName("collapsible");
    // for (var i = 0; i < coll.length; i++) {
    //   coll[i].addEventListener("click", function() {
    //     this.classList.toggle("active");
    //     var content = this.nextElementSibling;
    //     if (content.style.maxHeight) {
    //       content.style.maxHeight = null;
    //     } else {
    //       content.style.maxHeight = content.scrollHeight + "px";
    //     }
    //   });
    // }
  }
  openItem(itemId) {
    const divItem = document.getElementById(itemId);
    divItem.classList.toggle("contentHidden");
  }
}
