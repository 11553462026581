import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { Router, ActivatedRoute } from "@angular/router";
import { CartServiceService } from "src/app/services/cart-service.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "src/app/other/login/login.component";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  reviewList = [{}, {}, {}];
  product: any = null;
  images = [];
  properties = [];
  attributeName = "";
  selectedVariation = "";

  constructor(
    private firestoreService: FirestoreServiceService,
    private activeRoute: ActivatedRoute,
    private cartService: CartServiceService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private authService: AuthServiceService,
    public dialog: MatDialog,
    private router: Router
  ) {
    const routeParams = this.activeRoute.snapshot.params;
    this.loadProduct(routeParams.id);
  }

  ngOnInit() { }
  loadProduct(id) {
    this.firestoreService
      .getProductwithId(id)
      .get()
      .toPromise()
      .then((productSnapshot) => {
        // console.log(productSnapshot.data());
        this.product = productSnapshot.data();
        this.images = this.product.images;
        this.properties = this.product.properties;
        this.getAttribute(this.product.attributeId);
        if (this.product.rateType === "variable") {
          this.product.price = this.product.variations[0].price;
          this.selectedVariation = this.product.variations[0].name.replace(
            " ",
            ""
          );
        }
      });
  }
  onChangeRateSelect(price) {
    this.product.price = price;
    this.getVariation(price);
  }
  getVariation(price) {
    this.product.variations &&
      this.product.variations.forEach((variation) => {
        if (variation.price == price) {
          this.selectedVariation = variation.name.replace(" ", "");
          // console.log(variation.name);
        }
      });
  }
  getAttribute(attributeId) {
    if (attributeId !== "") {
      this.firestoreService
        .getAttributebyId(attributeId)
        .toPromise()
        .then((attribute) => {
          // console.log(attribute.data());
          this.attributeName = attribute.data().name;
        });
    }
  }

  addProductToCart(buy) {
    let userId = "";
    if (!this.authService.loggedIn) {
      this.openLoginDialog();
      this.showWarningToast("Login Error", "Please login first");
      return;
    } else {
      userId = this.authService.currentUser.uid;
      // console.log(userId);
    }
    let product;
    if (this.product.price == "") {
      this.showWarningToast(
        "Select product " + this.attributeName,
        "from dropdown"
      );
      return;
    }
    product = this.product;
    product.title = product.title;
    product.attributeName = this.attributeName;
    product.count = "1";
    product.variation = this.selectedVariation;
    product.imageCover = this.product.images[0].imageUrl;
    this.ngxService.start();
    this.cartService
      .addProductToCart({ ...product }, userId)
      .then((data) => {
        this.showSuccessToast("Added to Cart", "");
        this.ngxService.stop();
        if (buy === "yes") {
          this.router.navigate(["../../cart"]);
        }
        this.cartService.addCartCount(1)
      })
      .catch((error) => {
        console.log(error);
        this.ngxService.stop();
      });
  }
  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }

  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }

  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "450px",
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      // this.animal = result;
    });
  }
  add;
}
