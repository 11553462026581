import { Component, OnInit } from "@angular/core";
import { CartServiceService } from "src/app/services/cart-service.service";
import { AngularFireAuth } from "@angular/fire/auth";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "src/app/other/login/login.component";
import { HttpClient } from "@angular/common/http";
import { AddressComponent } from "src/app/other/address/address.component";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  cartItems = [];
  itemPrice = 0;
  totalPrice = 0;
  totalItems = 0;
  deliveryCharges = 0;
  isLoggedIn = false;
  userAddress = null;
  distanceInKM = "";
  distance = 0;

  date = new Date(new Date().getTime() + 3 * 60 * 60 * 24 * 1000);
  constructor(
    private cartService: CartServiceService,
    public afAuth: AngularFireAuth,
    private authService: AuthServiceService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private http: HttpClient
  ) {
    afAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        this.loadCartItems(user.uid);
        // this.loadCartItems(user.uid);
        this.loadAddress(user.uid);
        // console.log(user.uid);
        this.isLoggedIn = true;
      }
    });
  }

  ngOnInit() {
    // const userId =
    //   this.authService.currentUser && this.authService.currentUser.uid;
    // if (userId) {
    //   this.loadCartItems(userId);
    // }
  }

  loadCartItems(userId) {
    // console.log(userId);
    this.totalPrice = 0;
    this.totalItems = 0;
    this.cartService
      .getCartItems(userId)
      .toPromise()
      .then((itemsSnapshot) => {
        this.cartItems = [];
        itemsSnapshot.forEach((item) => {
          const product = item.data();
          if (!product.count) {
            product.count = 1;
            this.updateCartProduct(product, 1);
          }
          this.cartItems.push(product);
          this.totalItems += product.count * 1;
          this.totalPrice += product.count * product.price;
        });
        this.itemPrice = this.totalPrice;
        // this.totalPrice += this.deliveryCharges;
        this.cartService.setCartCount(itemsSnapshot.size);
      });
  }
  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "450px",
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
      // this.animal = result;
    });
  }
  removeProductFromCart(id, variation) {
    // console.log(id);
    // removeProductFromCart
    this.ngxService.start();
    const userId = this.authService.currentUser.uid;
    this.cartService
      .removeProductFromCart(id + variation, userId)
      .then((data) => {
        this.ngxService.stop();
        // this.showSuccessToast("Updated","")
        // console.log(data);
        this.loadCartItems(userId);
      })
      .catch((error) => {
        // console.log(error);
        this.ngxService.stop();
      });
  }
  updateCartProduct(product, count) {
    this.ngxService.start();
    product.count = count;
    const userId = this.authService.currentUser.uid;
    // console.log(userId);

    this.cartService
      .updateCartProduct(userId, product)
      .then((data) => {
        this.ngxService.stop();
        // this.showSuccessToast("Updated","")
        // console.log(data);
        this.loadCartItems(userId);
      })
      .catch((error) => {
        // console.log(error);
        this.ngxService.stop();
      });
  }
  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }

  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }

  loadAddress(userId) {
    this.authService
      .getUserAddressWithId(userId)
      .get()
      .toPromise()
      .then((userSnapshot) => {
        if (userSnapshot.data()) {
          // console.log(userSnapshot.data());
          this.userAddress = userSnapshot.data();
          this.getDistance(this.userAddress.pincode, "193101");
        }
      })
      .catch((error) => {
        this.toastr.error(error.message);
      });
  }
  getDistance(pincode1, pincode2) {
    const distanceurl =
      "https://maps.googleapis.com/maps/api/distancematrix/json?origins=" +
      pincode1 +
      "&destinations=" +
      pincode2 +
      "&key=AIzaSyDIWQgJxvKtO9z94MpH3DRMcFEvilPLhXc";
    return this.http
      .post("https://us-central1-igc-shop.cloudfunctions.net/getDistance", {
        url: distanceurl,
      })
      .subscribe(
        (res) => {
          // console.log(res["rows"][0].elements[0].distance);
          if (res["rows"][0].elements[0].distance.value) {
            this.distance = res["rows"][0].elements[0].distance.value;
            this.deliveryCharges = parseInt(
              ((this.distance * 14) / 1000).toString()
            );
            // console.log(this.deliveryCharges);
            this.distanceInKM = res["rows"][0].elements[0].distance.text;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
