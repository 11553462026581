import { CoverComponent } from './admin/cover/cover.component';
import { ServiceRequestsComponent } from './admin/service-requests/service-requests.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { AdminComponent } from "./admin/admin.component";
import { CategoryComponent } from "./products/category/category.component";
import { ProductComponent } from "./products/product/product.component";
import { CartComponent } from "./products/cart/cart.component";
import { CheckoutComponent } from "./products/checkout/checkout.component";
import { LoginComponent } from "./other/login/login.component";
import { OrdersComponent } from "./products/orders/orders.component";
import { ShortlistComponent } from "./products/shortlist/shortlist.component";
import { ProfileComponent } from "./other/profile/profile.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { AllProductsComponent } from "./admin/all-products/all-products.component";
import { AddProductsComponent } from "./admin/add-products/add-products.component";
import { AllPaymentsComponent } from "./admin/all-payments/all-payments.component";
import { CategoriesComponent } from "./admin/categories/categories.component";
import { AllUsersComponent } from "./admin/all-users/all-users.component";
import { HomeScreenComponent } from "./admin/home-screen/home-screen.component";
import { AllOrdersComponent } from "./admin/all-orders/all-orders.component";
import { AttributesComponent } from "./admin/attributes/attributes.component";
import { EditProductComponent } from "./admin/edit-product/edit-product.component";
import { PrivacyComponent } from './other/privacy/privacy.component';
import { TermsComponent } from './other/terms/terms.component';
import { AboutUsComponent } from './other/about-us/about-us.component';

const routes: Routes = [
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "admin",
    component: AdminComponent,
    children: [

      {
        path: "cover",
        component: CoverComponent
      },
      {
        path: "dashboard",
        component: DashboardComponent
      },
      {
        path: "allProducts",
        component: AllProductsComponent
      },
      {
        path: "add-product",
        component: AddProductsComponent
      },
      {
        path: "edit-product/:id",
        component: EditProductComponent
      },
      {
        path: "payments",
        component: AllPaymentsComponent
      },
      {
        path: "all-orders",
        component: AllOrdersComponent
      },
      {
        path: "categories",
        component: CategoriesComponent
      },
      {
        path: "attributes",
        component: AttributesComponent
      },
      {
        path: "users",
        component: AllUsersComponent
      },
      {
        path: "home-screen",
        component: HomeScreenComponent
      },
      {
        path: "service-requests",
        component: ServiceRequestsComponent
      }
    ]
  },
  {
    path: "category/:id",
    component: CategoryComponent
  },
  {
    path: "product/:id",
    component: ProductComponent
  },
  {
    path: "cart",
    component: CartComponent
  },
  {
    path: "checkout",
    component: CheckoutComponent
  },
  {
    path: "profile",
    component: ProfileComponent
  },
  {
    path: "orders",
    component: OrdersComponent
  },
  {
    path: "shortlist",
    component: ShortlistComponent
  },
  {
    path: "privacy",
    component: PrivacyComponent
  },
  {
    path: "terms",
    component: TermsComponent
  },
  {
    path: "about-us",
    component: AboutUsComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
