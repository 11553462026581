import { Component, OnInit, Inject } from "@angular/core";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
declare var google: any;

@Component({
  selector: "app-address",
  templateUrl: "./address.component.html",
  styleUrls: ["./address.component.scss"]
})
export class AddressComponent implements OnInit {
  loginView = true;
  latlng = ""
  user = {
    name: "",
    email: "",
    phone: "",
    place: "",
    city: "",
    district: "",
    pincode: "",
    village: "",
    street: "",
    userId: "",
    latlng: ""
  };
  address = {}
  constructor(
    public dialogRef: MatDialogRef<AddressComponent>,

    private authService: AuthServiceService,
    private ngxService: NgxUiLoaderService,
    private firestoreService: FirestoreServiceService,
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    // console.log("Dialog data: ", data);
    this.user = data;
  }

  ngOnInit() {
    this.initialize()
  }
  initialize() {
    var input = document.getElementById('searchTextField');
    var autocomplete = new google.maps.places.Autocomplete(input);
    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();
      // console.log("place: ", place);
      this.latlng = place.geometry.location.lat() + "," + place.geometry.location.lng()
      this.user.latlng = this.latlng;
      this.user.place = place.formatted_address;
    });
  }

  // login() {
  //   if (this.validLgin()) {
  //     this.ngxService.start();
  //     this.authService
  //       .login(this.user.email.trim(), this.user.password.trim())
  //       .then(data => {
  //         // console.log(data);
  //         this.showSuccessToast("Welcome name", "Login successful");
  //         this.dialogRef.close();
  //         this.ngxService.stop();
  //       })
  //       .catch(err => {
  //         console.log(err.message);
  //         this.showErrorToast("Error", err.message);
  //         this.ngxService.stop();
  //       });
  //   }
  // }
  // validLgin() {
  //   if (this.user.email.trim() == "") {
  //     this.showErrorToast("Email error", "Enter email");
  //     return false;
  //   }
  //   if (this.user.password.trim() == "") {
  //     this.showErrorToast("Password error", "Enter password");
  //     return false;
  //   }
  //   return true;
  // }

  saveAddress() {
    this.ngxService.start();
    this.firestoreService.saveAddress(this.user.userId, this.user).then(data => {
      this.dialogRef.close();
      this.ngxService.stop();
    });
  }

  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }
}
