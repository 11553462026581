import { Component, OnInit } from "@angular/core";
import { AuthServiceService } from "../services/auth-service.service";
import { ToastrService } from "ngx-toastr";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnInit {
  loggedIn = false;
  showPasswordFeild = false;
  admin = {
    email: "",
    password: ""
  };
  constructor(
    public afAuth: AngularFireAuth,
    private authService: AuthServiceService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private router: Router
  ) {
    afAuth.auth.onAuthStateChanged(user => {
      if (user) {
        // console.log(user);
        this.getUserDetails(user.uid);
        this.getUserDetails(user.uid);
      } else {
        // console.log("logged out");
        this.loggedIn = false;
      }
    });
  }
 
 
 
 
  getUserDetails(userId) {
    // this.ngxService.start();

    this.authService
      .getUserDetailsWithId(userId)
      .get()
      .toPromise()
      .then(userSnapshot => {
        // this.ngxService.stop();
        if (
          userSnapshot.data() &&
          userSnapshot.data()["userType"] === "admin"
        ) {
          this.loggedIn = true;
          // MOVE ROUTER
          this.router.navigate(['admin/dashboard']);


          // console.log(userSnapshot.data());
        } else {
          this.loggedIn = false;
          this.afAuth.auth.signOut();
          this.toastr.error("Not a valid admin");
        }
      })
      .catch(error => {
        // this.ngxService.stop();
        this.toastr.error(error.message);
      });
  }
  ngOnInit() {}
  nextStep() {
    this.authService
      .getAdminDetails()
      .get()
      .toPromise()
      .then(adminSnapshot => {
        if (
          adminSnapshot.data() &&
          adminSnapshot.data()["email"] === this.admin.email
        ) {
          this.showPasswordFeild = true;
        } else {
          this.showPasswordFeild = false;
          this.toastr.error("Wrong email address");
        }
      })
      .catch(error => {
        this.toastr.error(error.message);
      });
  }
  login() {
    this.authService
      .login(this.admin.email, this.admin.password)
      .then(data => {
        this.getUserDetails(data.user.uid);
      })
      .catch(error => {
        this.toastr.error(error.message);
      });
  }
}
