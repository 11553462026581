import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root"
})
export class AuthServiceService {
  loggedIn = false;
  currentUser = null;

  constructor(public afAuth: AngularFireAuth, private db: AngularFirestore) {
    afAuth.auth.onAuthStateChanged(user => {
      if (user) {
        // console.log(user);
        this.currentUser = user;
        this.loggedIn = true;
      } else {
        // console.log("logged out");
        this.loggedIn = false;
        this.currentUser = null;
      }
    });
  }

  login(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email, password);
  }

  forgot(email) {
    return this.afAuth.auth.sendPasswordResetEmail(email)
  }
  signUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password);
  }
  logout() {
    this.afAuth.auth.signOut();
    // this
  }
  addUsertoDatabase(user) {
    if (!user) {
      return;
    }
    user.time = new Date().getTime();
    return this.db
      .collection("users")
      .doc(user.id)
      .set(user);
  }

  getAdminDetails() {
    return this.db.collection("admins").doc("admin");
  }
  getUserDetailsWithId(userId) {
    return this.db.collection("users").doc(userId);
  }
  getUserAddressWithId(userId) {
    return this.db
      .collection("users")
      .doc(userId)
      .collection("addresses")
      .doc("1");
  }
}
