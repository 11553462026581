import { Component, OnInit, Inject } from "@angular/core";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-delete",
  templateUrl: "./delete.component.html",
  styleUrls: ["./delete.component.scss"],
})
export class DeleteComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DeleteComponent>) {}

  ngOnInit() {}
  calcel() {
    this.dialogRef.close(false);
  }
  delete() {
    this.dialogRef.close(true);
  }
}
