import { Component, OnInit } from '@angular/core';
import { DeleteComponent } from 'src/app/other/delete/delete.component';

import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { FirebaseApp } from "@angular/fire";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import * as firebase from "firebase/app";
import { FileUpload } from "src/app/classes/file-upload";
import { MatDialog } from "@angular/material";


@Component({
  selector: 'app-cover',
  templateUrl: './cover.component.html',
  styleUrls: ['./cover.component.scss']
})
export class CoverComponent implements OnInit {

  images = [];

  coverImages = [];
  imagesArray = {};

  // file uploading
  imageUrl = "";
  uploading = false;
  uploadPercent = 0;
  uploaded = false;

  private pdfPath = "/productImages";
  selectedFile = null;
  currentUpload: any = null;
  fileType;

  constructor(
    private db: AngularFirestore,
    private firestoreService: FirestoreServiceService,
    private firebaseApp: FirebaseApp,
    private ngxService: NgxUiLoaderService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private router: Router
  ) {
    // const routeParams = this.activeRoute.snapshot.params;
    this.loadImages();
  }
  loadImages() {
    this.ngxService.start();
    this.firestoreService
      .getAllCoverImages()
      .toPromise()
      .then((querySnapshot) => {
        this.coverImages = [];
        querySnapshot.forEach((doc) => {
          this.coverImages.push(doc.data());
        });
        this.ngxService.stop();
        console.log(this.coverImages)
      });
  }
  ngOnInit() { }

  addSliderImage(url) {
    this.firestoreService
      .addCoverImageToFirestore({
        imageUrl: url,
        fileType: this.fileType
      })
      .then((data) => {
        this.showSuccessToast("Cover Image Added", "");
        this.ngxService.stop();
        this.fileType = null;
        this.selectedFile = null;
        this.currentUpload = null;
        this.loadImages();
      })
      .catch((error) => {
        this.ngxService.stop();
      });
  }
  deleteCoverImage(imageId) {
    this.ngxService.start();
    this.firestoreService
      .deleteCoverImage(imageId)
      .then((data) => {
        this.showSuccessToast("Deleted", "Image Deleted");
        this.ngxService.stop();
        this.loadImages();
      })
      .catch((e) => {
        // console.log(e);
        this.showErrorToast("Error", e.message);
        this.ngxService.stop();
      });
  }

  //--------------------------------------------------
  //Called When File is Selected To Upload As Cover 
  //---------------------------------------------------
  detectFiles(event) {

    // console.log(event.target.files[0]);
    this.selectedFile = event.target.files[0];



    if (this.selectedFile) {
      this.fileType = this.getFileType(this.selectedFile)
      if (!(this.fileType == 'image' || this.fileType == 'video')) {
        this.toastr.error("select an image  or Video");
        this.selectedFile = null;
        this.fileType = null;

        return;
      }
      if (this.selectedFile.size / 1024 / 1024 > 40) {
        this.toastr.error("File Size Should be less than 40MB");
        return;
      }

      if (this.fileType == 'video') {

        if (this.fileType.duration > 60) {
          this.toastr.error("Video Duration should be 60 Seconds Only");
          return;
        }
      }

      const file = this.selectedFile;
      this.currentUpload = new FileUpload(file);
      this.pushUploadPDF(this.currentUpload);
      this.selectedFile = null;


    } else {
      // this.toastr.error("select an image file...")
      // console.log("select PDF");
    }
  }

  getFileType(file) {
    if (file.type.match('image.*'))
      return 'image';

    if (file.type.match('video.*'))
      return 'video';

    return null;

  }



  pushUploadPDF(upload) {
    const storageRef = this.firebaseApp.storage().ref();
    const uploadTask = storageRef
      .child(`${this.pdfPath}/${this.getRandomNumber() + upload.file.name}`)
      .put(upload.file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) => {
        this.uploading = true;
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        this.uploadPercent = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        // console.log(this.uploadPercent);
      },
      (error) => {
        // this.uploading = false;
        // upload failed
        // console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.uploaded = true;
          this.uploading = false;
          this.uploadPercent = 0;
          // this.newNotification.fileUrl = downloadURL;
          this.addSliderImage(downloadURL);
        });
      }
    );
  }

  getRandomNumber() {
    let num = "";
    for (let i = 0; i < 15; i++) {
      num = num + "" + Math.floor(Math.random() * 10);
    }
    return num;
  }

  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }

  openDeleteDialog(imageId): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "450px",
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteCoverImage(imageId);
      }
    });
  }

  //---------------------------------------------
  //Video Play 
  //---------------------------------------------
  playPause(url) {

    window.open(url)
    //  var myVideo: any = document.getElementById("my_video_1");
    // if (myVideo.paused) myVideo.play();
    // else myVideo.pause();
  }

}
