import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { Subscription } from "rxjs";
import { CartServiceService } from "src/app/services/cart-service.service";

@Component({
  selector: "app-category",
  templateUrl: "./category.component.html",
  styleUrls: ["./category.component.scss"]
})
export class CategoryComponent implements OnInit {
  featuredList = [];
  loading = false;
  routeSubscription: Subscription;
  menuOpen = false;
  isMobile = false;
  categoryName = ''
  constructor(
    private firestoreService: FirestoreServiceService,
    private activeRoute: ActivatedRoute,
    private cartService: CartServiceService
  ) {
    const routeParams = this.activeRoute.snapshot.params;
    this.loadProducts(routeParams.id);

    this.routeSubscription = activeRoute.params.subscribe(params => {
      this.loadProducts(params.id);
      this.loadSubCategory(params.id);
      // console.log("Route changed");
      this.cartService.toggleMenu(false)
    });
    this.cartService.onMenuToggle.subscribe(res => {
      // console.log(res);
      this.menuOpen = res;
    })
    this.isMobile = window.screen.width < 769;
    // console.log(this.isMobile);

  }

  ngOnInit() { }

  loadProducts(id) {
    this.loading = true;
    this.firestoreService
      .getProductswithCategoryId(id)
      .toPromise()
      .then(querySnapshot => {
        this.loading = false;
        this.featuredList = [];
        querySnapshot.forEach(doc => {
          this.featuredList.push(doc.data());
        });

      });
  }

  loadSubCategory(id) {
    this.firestoreService
      .getSubCategoryById(id)
      .get()
      .toPromise()
      .then((categorySnapshot) => {
        this.categoryName = categorySnapshot.data().name;
      });
  }
}
