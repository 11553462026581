import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { ToastrService } from "ngx-toastr";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-all-orders",
  templateUrl: "./all-orders.component.html",
  styleUrls: ["./all-orders.component.scss"],
})
export class AllOrdersComponent implements OnInit {
  searchText = "";
  orders = [];
  order = {};
  showOrders = true;
  constructor(
    private firestoreService: FirestoreServiceService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit() {
    this.loadFeaturedPrducts();
  }

  loadFeaturedPrducts() {
    this.ngxService.start();
    this.orders = [];
    this.firestoreService
      .getAllOrders()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {

          this.orders.push(doc.data());
        });
        this.ngxService.stop();
      });
  }

  //--------------------------------
  //SORTS ORDERS AS PER DATE
  //----------------------------------
  get sortData() {
    return this.orders.sort((a, b) => {
      // new Date(year, month, day, hours, minutes, seconds, milliseconds)
      //console.log(a.time.split('-'))
      try {
        let atime: any[] = a.time.split("-");
        //console.log(atime)
        let btime: any[] = b.time.split("-");
        let timea = atime[3].split(":");
        // console.log(timea)
        let timeb = btime[3].split(":");
        return (
          <any>new Date(btime[2], btime[1], btime[0], timeb[0], timeb[1]) -
          <any>new Date(atime[2], atime[1], atime[0], timea[0], timeb[1])
        );
      } catch (e) {
        return <any>new Date(a.time) - <any>new Date(b.time);
      }
    });
  }

  //--------------------------------
  //ORDER SHOW TOOGLE
  //----------------------------------
  showOrder(order) {
    this.showOrders = false;
    this.order = order;
  }

  showAllOrders() {
    this.order = null;
    this.showOrders = true;
  }

  updateOrder() {
    this.ngxService.start();
    this.firestoreService
      .updateOrder(this.order)
      .then((data) => {
        this.ngxService.stop();
        this.toastr.success("Success", "Order Updated succesfully");
      })
      .catch((err) => {
        this.ngxService.stop();
        this.toastr.error("Error", "Error Occured while updating the order!");
      });
  }

  markOrderConfirmed() {
    this.order["status"] = "Confirmed";

    this.updateOrder();
  }

  markOrderPending() {
    this.order["status"] = "pending";

    this.updateOrder();
  }
  changeOrderStatus(status) {
    this.order["status"] = status;
    this.updateOrder();
  }

  sendInvoice() {
    if (this.order) this.ngxService.start();
    this.firestoreService.sendInvoice(this.order).subscribe(
      (suc) => {
        this.ngxService.stop();
        this.toastr.success("Invoice sent to customer succesfully!", "Success");
      },
      (err) => {
        this.ngxService.stop();
        this.toastr.error("Failed to send invoice to customer", "Error");
        console.log(err);
      }
    );
  }
}
