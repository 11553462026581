import { Component, OnInit } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { CartServiceService } from "src/app/services/cart-service.service";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { ToastrService } from "ngx-toastr";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material";
import { AddressComponent } from "src/app/other/address/address.component";
import { NgxUiLoaderService } from "ngx-ui-loader";

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
})
export class CheckoutComponent implements OnInit {
  // cartItems = [{}, {}, {}];
  mode = "cod";
  orderId = "";
  distance = 0;
  cartItems = [];
  itemPrice = 0;
  totalPrice = 0;
  totalItems = 0;
  prods = "";
  deliveryCharges = 0;
  loggedIn = false;
  currentUser = null;
  userId = null;
  userAddress = null;
  orderPlaced = false;
  distanceInKM = "";
  invoiceNumber = 1701;

  date = new Date(new Date().getTime() + 3 * 60 * 60 * 24 * 1000);
  constructor(
    public afAuth: AngularFireAuth,
    private cartService: CartServiceService,
    private authService: AuthServiceService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService,
    private http: HttpClient,
    public dialog: MatDialog
  ) {
    afAuth.auth.onAuthStateChanged((user) => {
      if (user) {
        this.userId = user.uid;
        this.loadCartItems(user.uid);
        this.loadProfile(user.uid);
        this.loadAddress(user.uid);
        // console.log(user.uid);
        this.loggedIn = true;
      }
    });
  }

  ngOnInit() {}

  loadProfile(userId) {
    this.authService
      .getUserDetailsWithId(userId)
      .get()
      .toPromise()
      .then((userSnapshot) => {
        if (userSnapshot.data()) {
          // console.log(userSnapshot.data());
          this.currentUser = userSnapshot.data();
        }
      })
      .catch((error) => {
        this.toastr.error(error.message);
      });
  }
  loadAddress(userId) {
    this.authService
      .getUserAddressWithId(userId)
      .get()
      .toPromise()
      .then((userSnapshot) => {
        if (userSnapshot.data()) {
          // console.log(userSnapshot.data());
          this.userAddress = userSnapshot.data();
          this.userAddress.userId = userId;
          this.getDistance(this.userAddress.pincode, "193101");
          this.openAddressDialog();
        }
      })
      .catch((error) => {
        this.toastr.error(error.message);
      });
  }
  loadCartItems(userId) {
    // console.log(userId);
    this.totalPrice = 0;
    this.totalItems = 0;
    this.cartService
      .getCartItems(userId)
      .toPromise()
      .then((itemsSnapshot) => {
        this.cartItems = [];
        itemsSnapshot.forEach((item) => {
          const product = item.data();
          // if (!product.count) {
          //   product.count = 1;
          //   this.updateCartProduct(product, 1);
          // }
          this.cartItems.push(product);
          this.totalItems += product.count * 1;
          this.totalPrice += product.count * product.price;

          if (this.prods === "") {
            this.prods =
              product.title + "(" + product.count + ")" + "::" + product.price;
          } else {
            this.prods =
              this.prods +
              "---" +
              product.title +
              "(" +
              product.count +
              ")" +
              "::" +
              product.price;
          }
        });
        this.itemPrice = this.totalPrice;
        // this.totalPrice += this.deliveryCharges;
        // console.log(this.prods);
      });
    this.onGetInvoiceNumber();
  }
  onGetInvoiceNumber() {
    this.cartService
      .getInvoiceNumber()
      .toPromise()
      .then((invoiceSnapshot) => {
        if (invoiceSnapshot.data()) {
          // console.log(invoiceSnapshot.data());
          this.invoiceNumber = invoiceSnapshot.data().number;
          // console.log(this.invoiceNumber);
        }
      })
      .catch((error) => {
        this.toastr.error(error.message);
      });
    // console.log(this.getDateString());
  }
  getTwoDigits(num) {
    if (num < 10) {
      return "0" + num;
    } else {
      return num;
    }
  }
  getDateString() {
    return (
      this.getTwoDigits(new Date().getDate()) +
      "-" +
      this.getTwoDigits(new Date().getMonth() + 1) +
      "-" +
      new Date().getFullYear() +
      " - " +
      this.getTwoDigits(new Date().getHours()) +
      ":" +
      this.getTwoDigits(new Date().getMinutes())
    );
  }
  addToCodOrders() {
    this.orderId =
      "IGC" + (Math.floor(Math.random() * 90000000000) + 10000000000);
    let order = {
      cust_id: this.userId,
      amount: this.totalPrice + this.deliveryCharges,
      orderID: this.orderId,
      email: this.userAddress.email,
      phone: this.userAddress.phone,
      prods: this.prods,
      invoiceNumber: this.invoiceNumber,
      time: this.getDateString(),
      status: "pending",
      paymentType: this.mode,
      deliveryCharges: this.deliveryCharges,
      deliveryDistance: this.distanceInKM,
      deliveryType: "homedelivery",
      userName: this.userAddress.name,
      deliveryAddress:
        this.userAddress.name +
        " - " +
        this.userAddress.phone +
        "\n" +
        this.userAddress.street +
        " - " +
        this.userAddress.village +
        " \n" +
        this.userAddress.city +
        " - " +
        this.userAddress.pincode,
    };
    this.ngxService.start();
    this.cartService
      .addProductToOrders(order, this.userId, this.cartItems)
      .then((data) => {
        // console.log(data);
        // this.cartService.addProductToOrder()
        this.ngxService.stop();
        this.orderPlaced = true;
      })
      .catch((error) => {
        console.log(error);
      });
  }
  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }

  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }

  getDistance(pincode1, pincode2) {
    const distanceurl =
      "https://maps.googleapis.com/maps/api/distancematrix/json?origins=" +
      pincode1 +
      "&destinations=" +
      pincode2 +
      "&units=imperial&key=AIzaSyDIWQgJxvKtO9z94MpH3DRMcFEvilPLhXc";
    return this.http
      .post("https://us-central1-igc-shop.cloudfunctions.net/getDistance", {
        url: distanceurl,
      })
      .subscribe(
        (res) => {
          // console.log(res["rows"][0].elements[0].distance);
          if (res["rows"][0].elements[0].distance.value) {
            this.distance = res["rows"][0].elements[0].distance.value;
            this.deliveryCharges = parseInt(
              ((this.distance * 14) / 1000).toString()
            );
            // console.log(this.deliveryCharges);
            this.distanceInKM = res["rows"][0].elements[0].distance.text;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  openAddressDialog(): void {
    const dialogRef = this.dialog.open(AddressComponent, {
      width: "100%",
      data: this.userAddress,
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log("The dialog was closed");
    });
  }
}
