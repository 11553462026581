import { Component, OnInit } from "@angular/core";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { ToastrService } from "ngx-toastr";
import { Router, ActivatedRoute } from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { MatDialog } from "@angular/material";
import { DeleteComponent } from "src/app/other/delete/delete.component";

@Component({
  selector: "app-all-products",
  templateUrl: "./all-products.component.html",
  styleUrls: ["./all-products.component.scss"],
})
export class AllProductsComponent implements OnInit {
  productList = [];
  searchText = "";
  constructor(
    private firestoreService: FirestoreServiceService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.loadFeaturedPrducts();
  }

  ngOnInit() { }

  loadFeaturedPrducts() {
    this.productList = [];
    this.firestoreService
      .getAllProducts()
      .toPromise()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.productList.push(doc.data());
        });
        // console.log(this.productList);
      });
  }
  deleteProduct(productId) {
    this.ngxService.start();
    this.firestoreService
      .deleteProduct(productId)
      .then((data) => {
        this.showSuccessToast(
          "Deleted",
          "Successfully deleted the category with id: " + productId
        );
        this.ngxService.stop();
      })
      .catch((e) => {
        // console.log(e);
        this.showErrorToast("Error", e.message);
        this.ngxService.stop();
      });
  }

  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }
  openDeleteDialog(productId): void {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "450px",
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteProduct(productId);
      }
    });
  }
}
