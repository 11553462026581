import { Injectable, EventEmitter } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CartServiceService {
  onMenuToggle: EventEmitter<boolean> = new EventEmitter();
  onCartSizeChange: EventEmitter<Number> = new EventEmitter();
  distance: any = 0;
  cartCount = 0;
  constructor(
    private db: AngularFirestore,

    private http: HttpClient
  ) {
    this.onCartSizeChange.subscribe(res => {
      console.log(res);
      this.cartCount = res
    })
  }

  toggleMenu(value) {
    this.onMenuToggle.emit(value);
  }
  setCartCount(value) {
    this.cartCount = value
    this.onCartSizeChange.emit(value);
  }
  addCartCount(value) {
    this.onCartSizeChange.emit(this.cartCount + value);
  }

  addProductToCart(product, userId) {
    if (!product) {
      return;
    }
    product.time = new Date().getTime();
    if (!product.variation) {
      product.variation = "";
    }
    product.title = product.title + " - " + product.variation;
    return this.db
      .collection("users")
      .doc(userId)
      .collection("cart")
      .doc(product.id + product.variation)
      .set(product);
  }
  removeProductFromCart(productId, userId) {
    if (!productId) {
      return;
    }
    return this.db
      .collection("users")
      .doc(userId)
      .collection("cart")
      .doc(productId)
      .delete();
  }
  addProductToOrders(order, userId, products) {
    let batch = this.db.firestore.batch()
    if (!order) {
      return;
    }
    const id = this.db.createId();
    order.time_in_mills = new Date().getTime();
    order.id = id;
    batch.set(this.db
      .collection("orders")
      .doc(order.id).ref, order);

    products.forEach(product => {
      batch.set(this.db
        .collection("orders")
        .doc(id)
        .collection("products").doc(product.id).ref, product);
    });
    return batch.commit()
  }
  getUserOrders(userId) {
    return this.db
      .collection("orders", (ref) =>
        ref.where("cust_id", "==", userId)
      )
      .get();
  }
  // addProductToOrder(orderId, product) {
  //   return this.db
  //     .collection("orders")
  //     .doc(orderId)
  //     .collection("products").doc(product.id).set(product);
  // }
  getCartItems(userId) {
    return this.db.collection("users").doc(userId).collection("cart").get();
  }
  getInvoiceNumber() {
    return this.db.collection("invoiceNumber").doc("last").get();
  }
  updateCartProduct(userId, product) {
    if (!product) {
      return;
    }
    return this.db
      .collection("users")
      .doc(userId)
      .collection("cart")
      .doc(product.id + product.variation)
      .set(product);
  }

  getDistance(pincode1, pincode2) {
    // const distanceurl =
    //   "https://maps.googleapis.com/maps/api/distancematrix/json?origins=" +
    //   pincode1 +
    //   "&destinations=" +
    //   pincode2 +
    //   "&departure_time=now&key=AIzaSyDwmXu5V22z6oVWmUFjUEYImiDomeBZzTw";
    // return this.http
    //   .post("https://us-central1-igc-shop.cloudfunctions.net/getDistance", {
    //     url: distanceurl
    //   })
    //   .subscribe(
    //     res => {
    //       console.log(res["rows"][0].elements[0].distance.value / 1000);
    //       if (res["rows"][0].elements[0].distance.value) {
    //         this.distance = res["rows"][0].elements[0].distance.value / 1000;
    //       }
    //     },
    //     err => {
    //       console.log(err);
    //     }
    //   );
  }
}
