import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/auth";
import { MatDialog } from "@angular/material";
import { LoginComponent } from "src/app/other/login/login.component";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { CartServiceService } from "src/app/services/cart-service.service";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"]
})
export class MainMenuComponent implements OnInit {
  currentUser = null;
  cartSize = 0;
  openMenu = false;

  constructor(
    public dialog: MatDialog,
    public authService: AuthServiceService,
    public afAuth: AngularFireAuth,
    private cartService: CartServiceService,
  ) {
    afAuth.auth.onAuthStateChanged(user => {
      if (user) {
        this.loadProfile(user.uid);
        this.loadCartItems(user.uid);
      }
    });

    this.cartService.onCartSizeChange.subscribe(res => {
      this.cartSize = res
    })
  }

  ngOnInit() { }
  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: "450px"
      // data: {name: this.name, animal: this.animal}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log("The dialog was closed");
      // this.animal = result;
    });
  }
  loadProfile(userId) {
    this.authService
      .getUserDetailsWithId(userId)
      .get()
      .toPromise()
      .then(userSnapshot => {
        if (userSnapshot.data()) {
          // console.log(userSnapshot.data());
          this.currentUser = userSnapshot.data();
        }
      })
      .catch(error => {
        // this.toastr.error(error.message);
      });
  }
  loadCartItems(userId) {
    this.cartService
      .getCartItems(userId)
      .toPromise()
      .then(itemsSnapshot => {
        this.cartSize = itemsSnapshot.size;
        this.cartService.setCartCount(itemsSnapshot.size)
      });
  }
  logoutApp() {
    this.authService.logout();
  }
  toggleMenu() {
    this.openMenu = !this.openMenu
    this.cartService.toggleMenu(this.openMenu)
  }
}
