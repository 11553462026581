import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { FileUpload } from "src/app/classes/file-upload";
import { FirebaseApp } from "@angular/fire";

import * as firebase from "firebase/app";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-add-products",
  templateUrl: "./add-products.component.html",
  styleUrls: ["./add-products.component.scss"]
})
export class AddProductsComponent implements OnInit {
  newProduct = {
    id: "",
    title: "",
    images: {},
    rateType: "simple",
    price: "",
    attributeId: "",
    variations: {},
    categoryId: "",
    subCategoryId: "",
    subCategoryName: "",
    properties: {}
  };
  images = [];

  categories = [];
  subCategories = [];
  attributes = [];

  selectedCategory = {
    id: "",
    name: "",
    time: ""
  };
  variations: any = [
    {
      name: "",
      price: ""
    }
  ];
  properties = [
    {
      name: "",
      value: ""
    }
  ];

  imagesArray = {};

  // file uploading
  imageUrl = "";
  uploading = false;
  uploadPercent = 0;
  uploaded = false;

  private pdfPath = "/productImages";
  selectedFile = null;
  currentUpload: any = null;

  constructor(
    private db: AngularFirestore,
    private firestoreService: FirestoreServiceService,
    private firebaseApp: FirebaseApp,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.loadCategories();
    this.loadAttributes();
  }

  ngOnInit() {}
  resetFeilds() {
    this.newProduct = {
      id: "",
      title: "",
      images: {},
      rateType: "simple",
      price: "",
      attributeId: "",
      variations: {},
      categoryId: "",
      subCategoryId: "",
      subCategoryName: "",
      properties: {}
    };
    this.variations = [
      {
        name: "",
        price: ""
      }
    ];
    this.properties = [
      {
        name: "",
        value: ""
      }
    ];
    this.images = [];
  }
  validateProduct() {
    if (this.images.length < 1) {
      this.showErrorToast("Image Error", "upload atleast one image");
      return false;
    }
    if (this.newProduct.title.trim() === "") {
      this.showErrorToast("Input Error", "Enter product title");
      return false;
    }
    if (this.newProduct.rateType === "simple" && this.newProduct.price == "") {
      this.showErrorToast("Input Error", "Enter product price");
      return false;
    }
    if (this.newProduct.rateType === "variable" && this.validateVariations()) {
      // this.showErrorToast("Input Error", "Enter product price");
      return false;
    }
    if (this.newProduct.categoryId === "") {
      this.showErrorToast("Input Error", "Select product Category");
      return false;
    }
    if (this.newProduct.subCategoryId === "") {
      this.showErrorToast("Input Error", "Select product Sub-Category");
      return false;
    }

    return true;
  }
  validateVariations() {
    if (this.variations.length < 1) {
      this.showErrorToast("Input Error", "Enter variable rate");
      return true;
    }
    let err = false;
    this.variations.forEach(variation => {
      if (variation.name.trim() == "" || variation.price == "") {
        this.showErrorToast("Price Error", "Fill all variation fields");
        err = true;
      }
    });
    if (err) {
      return true;
    }
    return false;
  }
  publishProduct() {
    if (this.validateProduct()) {
      this.ngxService.start();

      this.variations.sort(function(a, b) {
        return a.price - b.price;
      });

      if (this.newProduct.rateType === "variable") {
        this.newProduct.variations = this.variations;
        this.newProduct.price = "";
      }
      this.newProduct.properties = this.properties;
      this.newProduct.images = this.images;
      // console.log(this.newProduct);

      this.firestoreService
        .addProductToFirestore(this.newProduct)
        .then(data => {
          // console.log(data);
          this.showSuccessToast("Product Added Successfully", "");
          this.ngxService.stop();
          this.resetFeilds();
        })
        .catch(error => {
          // console.log(error);
          this.ngxService.stop();
        });
    }
  }
  loadCategories() {
    this.firestoreService
      .getAllCategories()
      .toPromise()
      .then(querySnapshot => {
        this.categories = [];
        querySnapshot.forEach(doc => {
          this.categories.push(doc.data());
        });
        console.log(this.categories)
        if (this.categories[0]) {
          this.selectedCategory = this.categories[0];
          this.loadSubCategories(this.categories[0].id);
        }
      });
  }
  loadSubCategories(categoryId) {
    // console.log(categoryId);

    // this.selectedCategory = category;

    this.subCategories = [];

    this.firestoreService
      .getAllSubCategories(categoryId)
      .toPromise()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.subCategories.push(doc.data());
        });
      });
  }

  loadAttributes() {
    this.firestoreService
      .getAllAttributes()
      .toPromise()
      .then(querySnapshot => {
        this.attributes = [];
        querySnapshot.forEach(doc => {
          this.attributes.push(doc.data());
        });
        // if (this.attributes[0]) {
        //   this.selectedAttribute = this.attributes[0];
        //   this.loadSubAttributes(this.attributes[0].id);
        // }
      });
  }
  removeViriation(i) {
    this.variations.splice(i, 1);
  }
  removeProperty(i) {
    this.properties.splice(i, 1);
  }

  addVariation() {
    this.variations.push({
      name: "",
      price: ""
    });
  }

  addProperty() {
    this.properties.push({
      name: "",
      value: ""
    });
  }

  detectFiles(event) {
    // console.log(event.target.files[0]);
    this.selectedFile = event.target.files[0];
    if (this.selectedFile) {
      const file = this.selectedFile;
      this.currentUpload = new FileUpload(file);
      this.pushUploadPDF(this.currentUpload);
      this.selectedFile = null;
    } else {
      // this.toastr.error("select an image file...")
      // console.log("select PDF");
    }
  }

  pushUploadPDF(upload) {
    const storageRef = this.firebaseApp.storage().ref();
    const uploadTask = storageRef
      .child(`${this.pdfPath}/${this.getRandomNumber() + upload.file.name}`)
      .put(upload.file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      snapshot => {
        this.uploading = true;
        // in progress
        const snap = snapshot as firebase.storage.UploadTaskSnapshot;
        this.uploadPercent = Math.round(
          (snap.bytesTransferred / snap.totalBytes) * 100
        );
        // console.log(this.uploadPercent);
      },
      error => {
        // this.uploading = false;
        // upload failed
        // console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.uploaded = true;
          this.uploading = false;
          this.uploadPercent = 0;
          // this.newNotification.fileUrl = downloadURL;
          this.addToImagesArray(downloadURL);
          // console.log(downloadURL);
        });
      }
    );
  }

  getRandomNumber() {
    let num = "";
    for (let i = 0; i < 15; i++) {
      num = num + "" + Math.floor(Math.random() * 10);
    }
    return num;
  }

  addToImagesArray(imageUrl) {
    // this.addToImagesArray.push()
    // console.log(imageUrl);
    this.images.push({
      imageUrl: imageUrl
    });
  }
  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }

  onChangeAttributeSelect(name) {}
}
