import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthServiceService } from "src/app/services/auth-service.service";

@Component({
  selector: "app-admin-side-menu",
  templateUrl: "./admin-side-menu.component.html",
  styleUrls: ["./admin-side-menu.component.scss"]
})
export class AdminSideMenuComponent implements OnInit {
  constructor(
    private authProvider: AuthServiceService,
    private router: Router
  ) {}

  ngOnInit() {}
  signOut() {
    this.authProvider.logout();
    // this.router.navigate(['home']);
  }
}
