import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-del-policy',
  templateUrl: './del-policy.component.html',
  styleUrls: ['./del-policy.component.scss']
})
export class DelPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
