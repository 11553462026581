import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-top-menu',
  templateUrl: './admin-top-menu.component.html',
  styleUrls: ['./admin-top-menu.component.scss']
})
export class AdminTopMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
