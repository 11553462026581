import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-strip',
  templateUrl: './social-strip.component.html',
  styleUrls: ['./social-strip.component.scss']
})
export class SocialStripComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
