import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  myroute = "";
  title = "IGC";
  constructor(private router: Router) {
    router.events.subscribe(val => {
      // console.log(
      //   router.url
      // );
      if (router.url.includes("admin")) {
        this.myroute = "admin";
      }
    });
  }
}
