import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";

@Injectable({
  providedIn: "root",
})
export class FirestoreServiceService {


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private db: AngularFirestore,
    private http: HttpClient) { }

  addProductToFirestore(product) {
    if (!product) {
      return;
    }
    const id = this.db.createId();
    product.id = id;
    product.time = new Date().getTime();
    return this.db.collection("products").doc(id).set(product);
  }

  updateProductToFirestore(product) {
    if (!product.id) {
      return;
    }
    // const id = this.db.createId();
    // product.id = id;
    product.lastUpdateTime = new Date().getTime();
    return this.db.collection("products").doc(product.id).set(product);
  }
  deleteProduct(prodId) {
    if (!prodId) {
      return null;
    }
    return this.db.collection("products").doc(prodId).delete();
  }
  getAllProducts() {
    return this.db.collection("products").get();
  }
  getLastProductsByLimit(limit) {
    return this.db
      .collection("products", (ref) => ref.orderBy("time", "desc").limit(limit))
      .get();
  }
  getRandomProducts(limit) {
    return this.db
      .collection("products", (ref) => ref.orderBy("id", "desc").limit(limit))
      .get();
  }

  addImageCover(imageCover) {
    if (!imageCover) {
      return;
    }
    const id = this.db.createId();
    imageCover.id = id;

    return this.db.collection("cover_images").doc(id).set(imageCover);
  }
  getImageCovers() {
    return this.db.collection("cover_images").get();
  }
  deleteImageCover(imageId) {
    if (!imageId) {
      return;
    }
    return this.db.collection("cover_images").doc(imageId).delete();
  }
  getProductwithId(productId) {
    return this.db.collection("products").doc(productId);
  }

  getProductswithCategoryId(subCategoryId) {
    return this.db
      .collection("products", (ref) =>
        ref.where("subCategoryId", "==", subCategoryId)
      )
      .get();
  }
  addCategories(category) {
    if (!category) {
      return;
    }
    const id = this.db.createId();
    category.id = id;
    category.time = new Date().getTime();
    return this.db.collection("categories").doc(id).set(category);
  }
  deleteCategory(catId) {
    if (!catId) {
      return null;
    }
    return this.db.collection("categories").doc(catId).delete();
  }
  addSubCategories(subCategory) {
    if (!subCategory) {
      return;
    }
    const id = this.db.createId();
    subCategory.id = id;
    subCategory.time = new Date().getTime();
    return this.db.collection("subCategories").doc(id).set(subCategory);
  }
  deleteSubCategory(subCatId) {
    if (!subCatId) {
      return null;
    }
    return this.db.collection("subCategories").doc(subCatId).delete();
  }

  addAttributes(attribute) {
    if (!attribute) {
      return;
    }
    const id = this.db.createId();
    attribute.id = id;
    attribute.time = new Date().getTime();
    return this.db.collection("attributes").doc(id).set(attribute);
  }
  getAttributebyId(attributeId) {
    if (!attributeId) {
      return;
    }
    return this.db.collection("attributes").doc(attributeId).get();
  }
  addSubAttribute(attributeId, subAttribute) {
    if (!attributeId) {
      return;
    }
    const id = this.db.createId();
    subAttribute.id = id;
    subAttribute.time = new Date().getTime();
    return this.db
      .collection("attributes")
      .doc(attributeId)
      .collection("subAttributes")
      .doc(id)
      .set(subAttribute);
  }

  getAllCategories() {
    return this.db.collection("categories", (ref) => ref.orderBy("name")).get();
  }
  getAllAttributes() {
    return this.db.collection("attributes").get();
  }
  getAllSubCategories(categoryId) {
    return this.db
      .collection("subCategories", (ref) =>
        ref.where("parentId", "==", categoryId)
      )
      .get();
  }
  getAllSubCategoriesForSideMenu() {
    return this.db
      .collection("subCategories", (ref) => ref.orderBy("name"))
      .get();
  }
  getSubCategoryById(subCategoryId) {
    return this.db.collection("subCategories").doc(subCategoryId);
  }
  getAllSubAttributes(attributeId) {
    return this.db
      .collection("attributes")
      .doc(attributeId)
      .collection("subAttributes")
      .get();
  }
  getAllOrders() {
    return this.db.collection("orders").get();
  }

  // DASHBOARD

  addCoverImageToFirestore(image) {
    if (!image) {
      return;
    }
    const id = this.db.createId();
    image.id = id;
    image.time = new Date().getTime();
    return this.db.collection("cover_images").doc(id).set(image);
  }

  deleteCoverImage(imageId) {
    if (!imageId) {
      return null;
    }
    return this.db.collection("cover_images").doc(imageId).delete();
  }
  getAllCoverImages() {
    return this.db.collection("cover_images").get();
  }

  //---------------------------------------------
  //SERVICE REQUESTS SECTION
  //---------------------------------------------

  getServiceRequests() {

    return this.db.collection("serviceRequests").get();
  }


  //-------------------------------------------------
  //ORDER MANAGEMENT
  //------------------------------------------------

  updateOrder(order) {
    return this.db.collection("orders").doc(order.id).update(order);
  }


  sendInvoice(order) {


    this.httpOptions.headers.append('Access-Control-Allow-Origin', 'true');
    this.httpOptions.headers.append('Access-Control-Allow-Credentials', 'true');
    let url = "https://us-central1-igc-shop.cloudfunctions.net/sendInvoice";
    return this.http.put(url, order);


  }

  saveAddress(userId, address) {
    if (!address) {
      return;
    }
    return this.db.collection("users").doc(userId).collection('addresses').doc('1').set(address);
  }
}



