import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirestoreServiceService } from "src/app/services/firestore-service.service";
import { FirebaseApp } from "@angular/fire";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import * as firebase from "firebase/app";
import { FileUpload } from "src/app/classes/file-upload";
import { MatDialog } from "@angular/material";
import { DeleteComponent } from "src/app/other/delete/delete.component";
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  newOrder=0;
productList=[];
  constructor(
    private firestoreService: FirestoreServiceService,
    private toastr: ToastrService,
    private ngxService: NgxUiLoaderService

  ){
this.getOrders();
  }
 ngOnInit(){

 }


 getOrders()
 {
  this.ngxService.start();
  
  this.firestoreService
    .getAllOrders()
    .toPromise()
    .then(querySnapshot => {
      querySnapshot.forEach(doc => {
        let data=doc.data();
       
        if(data.status=='pending')
        {
        // console.log(data)
          this.productList.push(data);

        }
       
      });
      this.newOrder=this.productList.length
      this.ngxService.stop();
  //   console.log(this.productList);
    });
 }
}
