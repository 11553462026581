import { CoverComponent } from './admin/cover/cover.component';
import { SortByPipe } from './pipes/SortByPipe';
import { ServiceRequestsComponent } from './admin/service-requests/service-requests.component';
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TopMenuComponent } from "./menus/top-menu/top-menu.component";
import { MainMenuComponent } from "./menus/main-menu/main-menu.component";
import { SideMenuComponent } from "./menus/side-menu/side-menu.component";
import { FeaturedComponent } from "./products/featured/featured.component";
import { TrendingComponent } from "./products/trending/trending.component";
import { CategoryComponent } from "./products/category/category.component";
import { SearchComponent } from "./products/search/search.component";
import { SliderComponent } from "./products/slider/slider.component";
import { DownloadComponent } from "./other/download/download.component";
import { AboutComponent } from "./other/about/about.component";
import { PaymentStripComponent } from "./other/payment-strip/payment-strip.component";
import { SocialStripComponent } from "./other/social-strip/social-strip.component";
import { ProductComponent } from "./products/product/product.component";
import { CartComponent } from "./products/cart/cart.component";
import { CheckoutComponent } from "./products/checkout/checkout.component";
import { HomeComponent } from "./home/home.component";
import { AdminComponent } from "./admin/admin.component";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {
  MatTreeModule,
  MatTabsModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatDialogModule,
} from "@angular/material";
import {
  MatIconModule,
  MatButtonToggleModule,
  MatButtonModule,
} from "@angular/material";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HospitalComponent } from "./products/hospital/hospital.component";
import { FooterComponent } from "./other/footer/footer.component";
import { AdminTopMenuComponent } from "./admin/admin-top-menu/admin-top-menu.component";
import { AdminSideMenuComponent } from "./admin/admin-side-menu/admin-side-menu.component";
import { LoginComponent } from "./other/login/login.component";
import { ProfileComponent } from "./other/profile/profile.component";
import { OrdersComponent } from "./products/orders/orders.component";
import { ShortlistComponent } from "./products/shortlist/shortlist.component";
import { AddProductsComponent } from "./admin/add-products/add-products.component";
import { EditProductComponent } from "./admin/edit-product/edit-product.component";
import { AllProductsComponent } from "./admin/all-products/all-products.component";
import { CategoriesComponent } from "./admin/categories/categories.component";
import { HomeScreenComponent } from "./admin/home-screen/home-screen.component";
import { AllOrdersComponent } from "./admin/all-orders/all-orders.component";
import { AllPaymentsComponent } from "./admin/all-payments/all-payments.component";
import { DashboardComponent } from "./admin/dashboard/dashboard.component";
import { AllUsersComponent } from "./admin/all-users/all-users.component";
import { AttributesComponent } from "./admin/attributes/attributes.component";
import { environment } from "src/environments/environment";

import { AngularFireModule } from "@angular/fire";
import {
  AngularFirestoreModule,
  FirestoreSettingsToken,
} from "@angular/fire/firestore";
import { AngularFireStorageModule } from "@angular/fire/storage";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FormsModule } from "@angular/forms";

import { ToastrModule } from "ngx-toastr";

import { NgxUiLoaderModule } from "ngx-ui-loader";
import { PrivacyComponent } from "./other/privacy/privacy.component";
import { TCComponent } from "./other/t-c/t-c.component";
import { DelPolicyComponent } from "./other/del-policy/del-policy.component";
import { RetPolicyComponent } from "./other/ret-policy/ret-policy.component";
import { HttpClientModule } from "@angular/common/http";
import { DeleteComponent } from "./other/delete/delete.component";
import { TermsComponent } from './other/terms/terms.component';
import { AboutUsComponent } from './other/about-us/about-us.component';
import { AddressComponent } from './other/address/address.component';

@NgModule({
  declarations: [
    AppComponent,
    TopMenuComponent,
    MainMenuComponent,
    SideMenuComponent,
    FeaturedComponent,
    TrendingComponent,
    CategoryComponent,
    SearchComponent,
    SliderComponent,
    DownloadComponent,
    AboutComponent,
    PaymentStripComponent,
    SocialStripComponent,
    ProductComponent,
    CartComponent,
    CheckoutComponent,
    HomeComponent,
    AdminComponent,
    HospitalComponent,
    FooterComponent,
    AdminTopMenuComponent,
    AdminSideMenuComponent,
    LoginComponent,
    DeleteComponent,
    ProfileComponent,
    OrdersComponent,
    ShortlistComponent,
    AddProductsComponent,
    EditProductComponent,
    AllProductsComponent,
    CategoriesComponent,
    HomeScreenComponent,
    AllOrdersComponent,
    AllPaymentsComponent,
    DashboardComponent,
    AllUsersComponent,
    AttributesComponent,
    PrivacyComponent,
    TCComponent,
    DelPolicyComponent,
    RetPolicyComponent,
    TermsComponent,
    AboutUsComponent,
    ServiceRequestsComponent,
    CoverComponent,
    SortByPipe,
    AddressComponent
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.config, "IGC"), // imports firebase/app needed for everything
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features

    FormsModule,
    Ng2SearchPipeModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatTreeModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatTabsModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatDialogModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added
    NgxUiLoaderModule,
  ],
  entryComponents: [LoginComponent, DeleteComponent, AddressComponent],
  providers: [{ provide: FirestoreSettingsToken, useValue: {} }],
  bootstrap: [AppComponent],
})
export class AppModule {}
