import { Component, OnInit } from "@angular/core";
import { CartServiceService } from "../services/cart-service.service";
import { FirestoreServiceService } from "../services/firestore-service.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  featuredList = [{}, {}, {}, {}];
  imageCovers = [];
  menuOpen = false;
  constructor(private firestoreService: FirestoreServiceService, private cartService: CartServiceService) {
    this.cartService.onMenuToggle.subscribe(res => {
      this.menuOpen = res;
    })
  }

  ngOnInit() {
    this.loadCoverImages();
  }

  loadCoverImages() {
    this.imageCovers = [];
    this.firestoreService
      .getImageCovers()
      .toPromise()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.imageCovers.push(doc.data());
        });
        // this.productList = this.productList.splice(0, 4);
      });
  }
}
