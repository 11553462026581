import { Component, OnInit, Inject } from "@angular/core";
import { AuthServiceService } from "src/app/services/auth-service.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginView = true;
  user = {
    email: "",
    password: ""
  };
  newUser = {
    id: "",
    name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: ""
  };
  constructor(
    public dialogRef: MatDialogRef<LoginComponent>,

    private authService: AuthServiceService,
    private ngxService: NgxUiLoaderService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {}

  forgotPassword() {
    if (this.user.email.trim() == "") {
      this.showErrorToast("Email error", "Enter email");
      return false;
    }
    this.ngxService.start();
    this.authService
      .forgot(this.user.email.trim())
      .then(data => {
        console.log(data);
        this.showSuccessToast("Forgot password", "Password reset link sent to email");
        this.dialogRef.close();
        this.ngxService.stop();
      })
      .catch(err => {
        console.log(err.message);
        this.showErrorToast("Error", err.message);
        this.ngxService.stop();
      });
  }

  login() {
    if (this.validLgin()) {
      this.ngxService.start();
      this.authService
        .login(this.user.email.trim(), this.user.password.trim())
        .then(data => {
          console.log(data);
          this.showSuccessToast("Welcome name", "Login successful");
          this.dialogRef.close();
          this.ngxService.stop();
        })
        .catch(err => {
          console.log(err.message);
          this.showErrorToast("Error", err.message);
          this.ngxService.stop();
        });
    }
  }
  validLgin() {
    if (this.user.email.trim() == "") {
      this.showErrorToast("Email error", "Enter email");
      return false;
    }
    if (this.user.password.trim() == "") {
      this.showErrorToast("Password error", "Enter password");
      return false;
    }
    return true;
  }

  validRegister() {
    if (this.newUser.name.trim() == "") {
      this.showErrorToast("Name error", "Enter name");
      return false;
    }
    if (this.newUser.email.trim() == "") {
      this.showErrorToast("Email error", "Enter email");
      return false;
    }
    if (this.newUser.phone.trim() == "") {
      this.showErrorToast("Phone error", "Enter phone number");
      return false;
    }
    if (this.newUser.password.trim() == "") {
      this.showErrorToast("Password error", "Enter password");
      return false;
    }
    if (this.newUser.password.trim().length < 6) {
      this.showErrorToast(
        "Password error",
        "Password length should be 6 characters or more"
      );
      return false;
    }
    if (this.newUser.password !== this.newUser.confirmPassword) {
      this.showErrorToast("Password error", "Passwords does not match");
      return false;
    }
    return true;
  }

  register() {
    if (this.validRegister()) {
      this.ngxService.start();
      this.authService
        .signUp(this.newUser.email, this.newUser.password)
        .then(data => {
          // console.log(data.user.uid);
          this.newUser.id = data.user.uid;
          this.showSuccessToast("Account created", "Adding user to database");
          this.authService
            .addUsertoDatabase(this.newUser)
            .then(data => {
              this.ngxService.stop();
              this.showSuccessToast("Registration complete", "");
              this.dialogRef.close();
            })
            .catch(error => {
              this.showErrorToast("Error", error.message);
              this.ngxService.stop();
            });
        })
        .catch(err => {
          // console.log(err.message);
          this.showErrorToast("Error", err.message);
          this.ngxService.stop();
        });
    }
  }

  showSuccessToast(title, msg) {
    this.toastr.success(msg, title);
  }
  showWarningToast(title, msg) {
    this.toastr.warning(msg, title);
  }
  showErrorToast(title, msg) {
    this.toastr.error(msg, title);
  }
}
