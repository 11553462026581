import { FirestoreServiceService } from 'src/app/services/firestore-service.service';
import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-service-requests',
  templateUrl: './service-requests.component.html',
  styleUrls: ['./service-requests.component.scss']
})

export class ServiceRequestsComponent implements OnInit {
  requests=[];

  constructor(private firestore:FirestoreServiceService, private ngxService: NgxUiLoaderService,) { }

  ngOnInit() {
    this.ngxService.start();
  this.getRequests();
  }
  
getRequests()
{

  this.firestore.getServiceRequests().toPromise().then(querySnapshot => {
    this.requests = [];
    querySnapshot.forEach(doc => {
      this.requests.push(doc.data());
      
    });
    this.ngxService.stop();
  });

 
}

}
