import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { CartServiceService } from 'src/app/services/cart-service.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  orders = [];
  loading = false;
  menuOpen = false;
  constructor(
    public afAuth: AngularFireAuth,
    private cartService: CartServiceService,) {

    afAuth.auth.onAuthStateChanged(user => {
      if (user) {
        this.loadOrders(user.uid);
      }
    });
    this.cartService.onMenuToggle.subscribe(res => {
      this.menuOpen = res;
    })
  }

  ngOnInit() {
  }

  loadOrders(userId) {
    this.loading = true;
    this.cartService
      .getUserOrders(userId)
      .toPromise()
      .then(itemsSnapshot => {
        this.orders = [];
        itemsSnapshot.forEach(item => {
          this.orders.push(item.data());
        });
        this.orders = this.orders.sort((a, b) => {
          let t1 = b.time.split('-');
          let t2 = a.time.split('-');
          return new Date(t1[1] + " " + t1[0] + " " + t1[2]).getTime() - new Date(t2[1] + " " + t2[0] + " " + t2[2]).getTime();
        })
        this.loading = false;
      });
  }

  openDetailsDialog(order) {

  }
}
